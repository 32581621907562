<template>
  <div class="top-menu">
    <el-dropdown v-if="user.userInfo && user.userInfo.userId" @command="handleCommand" trigger="click">
      <el-avatar el-dropdown-link :src="loadUrl(user.userInfo.headIcon)" @error="errorHandler">
        <span class="iconfont icon-touxiang"></span>
      </el-avatar>

      <el-dropdown-menu slot="dropdown" class="dropdown-list">
        <el-dropdown-item command="/user">
          <div class="uInfo">
            <el-avatar :size="28" :src="loadUrl(user.userInfo.headIcon)" @error="errorHandler">
              <span class="iconfont icon-touxiang"></span>
            </el-avatar>
            <div class="uInfo-text">
              <h2>{{ user.userInfo.userName }}</h2>
              <span>{{ user.userInfo.userAccount }}</span>
            </div>
          </div>
        </el-dropdown-item>
        <el-dropdown-item v-if="user.menuList.length > 0" command="admin" class="btn-admin">进入后台管理</el-dropdown-item>
        <el-dropdown-item command="/members">我的会员</el-dropdown-item>
        <el-dropdown-item command="/my-certification">我的认证</el-dropdown-item>
<!--        <el-dropdown-item command="/professional-certification">职业认证</el-dropdown-item>-->
<!--        <el-dropdown-item command="/publish">我的发布</el-dropdown-item>-->
        <el-dropdown-item command="/participation">我的参与</el-dropdown-item>
        <el-dropdown-item command="/order">我的订单</el-dropdown-item>
<!--        <el-dropdown-item command="/evaluate">我的评价</el-dropdown-item>-->
<!--        <el-dropdown-item command="/data-center">数据中心</el-dropdown-item>-->

        <el-dropdown-item command="9" divided>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- <span class="">入驻/合作</span> -->
    <div v-else class="login-link">
      <span @click="login(3)">注册</span> | <span @click="login(1)">登录</span>
    </div>
  </div>
</template>
<script>
import { getInfo, logout } from "@/api/user";
export default {
  name: "UserBar",
  data() {
    return {
      userInfo: {},
      menuList: [],
      permissionList: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {},
  mounted() {
    let _token = JSON.stringify(this.$route.query.taken);
    if (_token) {
      window.localStorage.setItem("token", _token);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.getInfos();
    }
  },
  methods: {
    getInfos() {
      getInfo().then((res) => {
        if (res.code == 200) {
          window.localStorage.setItem("user", JSON.stringify(res.data));
          this.$store.dispatch("saveUser", res.data);
          this.userInfo = res.data.userInfo;
          this.menuList = res.data.menuList;
          this.permissionList = res.data.permissionList;
        } else if (res.code == 600) {
          this.$message(res.msg);
          this.logouts();
        } else {
          // this.$message(res.msg);
          // window.location.href = process.env.VUE_APP_SAAS_URL;
        }
        this.$store.commit("hideLoading");
      });
    },
    login(active) {
      window.location.href =
        process.env.VUE_APP_SAAS_URL + "/login?redirect="+active;
    },
    logouts() {
      let _token = window.localStorage.getItem("token");
      logout(_token).then((res) => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("tenant_id");
        this.user = [];
        this.$store.dispatch("saveUser", []);
        this.golink();
      });
    },
    errorHandler() {
      return true;
    },
    handleCommand(command) {
      if (command == 9) {
        this.logouts();
      } else if (command == "admin") {
        window.location.href = `${process.env.VUE_APP_SAAS_URL}/home`;
      } else {
        this.$router.push(command);
        this.$store.dispatch("saveSideActive", command);
      }
    },
    golink() {
      this.$router.push("/index");
      this.$store.dispatch("saveHeadActive", "/index");
      this.$store.dispatch("saveSideActive", "/user");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-avatar > img {
  height: auto;
  width: 100%;
}
.top-menu {
  .icon-touxiang {
    font-size: 40px;
  }
  .el-dropdown {
    cursor: pointer;
  }
  .login-link {
    display: inline-block;
    padding-left: 20px;
    font-size: 14px;
    color: #666;
    span {
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      &:hover {
        color: #1e50ae;
      }
    }
  }
}
.dropdown-list {
  padding: 10px 20px;
  width: 150px;
  .icon-touxiang {
    font-size: 28px;
  }
  .el-dropdown-menu__item {
    padding: 0 10px;
    border-radius: 4px;
    &:hover {
      background: #ebf4fd;
    }
  }
  .btn-admin {
    position: relative;
    margin: 10px 0;
    width: 130px;
    height: 40px;
    font-size: 14px;
    background: #1e50ae;
    box-shadow: 0px 0px 21px 0px rgba(30, 80, 174, 0.13);
    border-radius: 6px;
    color: #fefefe;
    margin-bottom: 25px;
    text-align: center;
    &:after {
      position: absolute;
      content: "";
      border-bottom: 1px solid #efefef;
      top: 100%;
      width: 100%;
      padding-top: 12px;
      left: 0;
    }
    &:hover {
      background: #3863b3;
    }
  }
  .uInfo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // border-bottom: 1px solid #EFEFEF ;
    padding: 6px 0;
    margin-bottom: 24px;
    &:after {
      position: absolute;
      content: "";
      border-bottom: 1px solid #efefef;
      top: 100%;
      width: 100%;
      padding-top: 12px;
      left: 0;
    }
    .uInfo-text {
      padding-left: 10px;
      font-weight: 400;
      line-height: 14px;
      h2 {
        font-size: 14px;
        color: #333333;
        padding-bottom: 6px;
      }
      span {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .top-menu {
    .el-dropdown {
      .el-avatar {
        width: 30px;
        height: 30px;
        line-height: 31px;
        span {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
