<template>
  <div class="user-header">
    <h1 class="user-logo" @click="golink">
      <a v-if="logo" href="javascript:;"><img :src="loadUrl(logo)" alt="" /></a>
      <!--      <a v-else href="javascript:;"><img src="../assets/image/logo2.png" alt=""/></a>-->
      <a v-else href="javascript:;" class="title">松冬智慧体育服务平台</a>
      <span class="user-text">个人中心</span>
    </h1>

    <user-bar />
  </div>
</template>
<script>
import { getTenant } from "@/api/user.js";
import UserBar from "./UserBar.vue";
export default {
  name: "UserHeader",
  components: {
    UserBar,
  },
  data() {
    return {
      logo: "",
      type: "",
      rehref: "",
      tenant_id: "",
    };
  },
  mounted() {
    this.getTenantLogo();
  },
  methods: {
    // 获取租户的信息
    getTenantLogo() {
      const self = this;
      let ip = window.location.href.split("/#")[0];
      getTenant().then((res) => {
        if (res.code == 200 && res.data) {
          this.type = res.data.type;
          this.logo = res.data.loginLogo;
          this.rehref =
            res.data.type == 1
              ? `${ip}/#/template-first`
              : res.data.type == 2
              ? `${ip}/#/template-second`
              : res.data.type == 3
              ? `${ip}/#/template-third`
              : "";
        } else {
          // self.$message(res.msg);
        }
      });
    },
    golink() {
      if (this.type != "") {
        window.location.href = this.rehref;
      } else {
        this.$store.dispatch("saveHeadActive", "/index");
        this.$router.push("/index");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-header {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ececec;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  .user-logo {
    line-height: 60px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    a {
      display: inline-block;
      line-height: 34px;
    }
    img {
      width: 130px;
      display: inline-block;
      line-height: 34px;
    }
    .title {
      font-size: 16px;
      color: #333;
    }
  }
  .user-text {
    font-size: 16px;
    font-weight: 400;
    color: #454545;
    padding-left: 63px;
    cursor: pointer;
  }

  .top-menu {
    padding-right: 20px;
    .icon-touxiang {
      font-size: 40px;
    }
    .el-dropdown {
      cursor: pointer;
    }
    .login-link {
      display: inline-block;
      padding-left: 20px;
      font-size: 14px;
      color: #666;
      span {
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        &:hover {
          color: #1e50ae;
        }
      }
    }
  }
}
</style>
