<template>
  <div class="course-content">
    <user-header />
    <div class="course-main">
      <router-view></router-view>
    </div>

    <Loading v-if="LOADING" />
  </div>
</template>

<script>
import UserHeader from "@/components/UserHeader";
import Loading from "@/components/Loading";
import { mapState } from "vuex";
export default {
  name: "user-home",
  components: {
    UserHeader,
    Loading,
  },
  computed: {
    ...mapState(["LOADING"]),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.course-content {
  position: relative;
  min-height: 1080px;
  padding-top: 60px;
  margin: auto;
  box-sizing: border-box;
  background-color: #f0f0f0;
  padding-bottom: 60px;
  .course-main {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    background-color: #f0f0f0;
    height: 100%;
  }
}
</style>

